import React, {Component} from 'react';
import Helmet from 'react-helmet'
import lib from 'library'

import TitleBar from 'components/TitleBar.js'
import SectionCompanyInfo from 'components/SectionCompanyInfo.js'
import SectionMissions from 'components/SectionMissions.js'
import SectionGallery from 'components/SectionGallery.js'
import Layout from "components/layout"

export default class App extends Component {
  render() {

    const lang = lib.lc.currentLang(this.props.location.pathname)

    return (<Layout pathname={this.props.location.pathname}>

      <div >
        <Helmet title={lc[lang].pagename} meta={[
            {
              name: 'description',
              content: 'Sample'
            }, {
              name: 'keywords',
              content: 'sample, something'
            }
          ]}/>

        <div style={{
            height: 50
          }}/>
        <TitleBar title={lc[lang].companyinfo}/>
        <SectionCompanyInfo pathname={this.props.location.pathname}/>
        <div style={{
            height: 100
          }}/>
        <TitleBar title={lc[lang].missions}/>
        <SectionMissions pathname={this.props.location.pathname}/>
        <div style={{
            height: 100
          }}/>

        <TitleBar title={lc[lang].missions}/>
        <SectionMissions pathname={this.props.location.pathname}/>
        <div style={{
            height: 100
          }}/>

        <TitleBar title={lc[lang].activity}/>
        <SectionGallery array={arrayActivity}/>
        <div style={{
            height: 100
          }}/>

      </div>
    </Layout>)
  }
}

const lc = {
  en: {
    pagename: 'About us - Kun Shung Holdings Limited',
    companyinfo: 'Who We Are',
    missions: 'Our Missions',
    activity: 'Photos of Activities'
  },
  zh: {
    pagename: '關於我們 - 冠信控股有限公司',
    companyinfo: '公司簡介',
    missions: '主要使命',
    activity: '活動照片'
  }
}

const arrayActivity = [
  require('assets/activity/20171101_163953.jpg'),
  require('assets/activity/IMG-20190520-WA0002.jpg'),
  require('assets/activity/IMG-20190520-WA0003.jpg'),
  require('assets/activity/IMG-20190520-WA0004.jpg'),
  require('assets/activity/01.jpg'),
  require('assets/activity/02.jpg')
]
